<div id="content" class="mb-0" *ngIf="page?.active">
    <img *ngIf="page?.banner_image" src="{{page.banner_image}}" alt="{{page.page_title}}">
    <div class="widget" *ngIf="!loading">
        <div class="row mb-m" *ngIf="page.back_link">
            <a href="{{page.back_link}}">
                <i class="material-icons"> keyboard_arrow_left </i> Zurück
            </a>
        </div>

        <h2 class="pb-s" *ngIf="page.show_title">{{page.page_title}}</h2>
        <app-page-content
            [body]="body"
            [public]="page.public"
            *ngIf="link !== 'leistungen' && link !== 'wissen' && link !== 'pkp-geburtstag'"></app-page-content>
        <app-linda-leistungen *ngIf="link == 'leistungen'"></app-linda-leistungen>
        <app-linda-wissen *ngIf="link == 'wissen'"></app-linda-wissen>
        <app-pkp-geburtstag *ngIf="link == 'pkp-geburtstag'"></app-pkp-geburtstag>
    </div>
</div>
<app-page-footer [foot]="footer" *ngIf="!loading"></app-page-footer>
